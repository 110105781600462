<template>    
    <b-container>
    <b-overlay rounded="sm" :show="show">
        <b-card title="">
            <table class="table table-responsive" v-if="notifications.length>0">
                <thead>
                    <tr>
                        <th>OT</th>
                        <th>Autor / Fecha</th>
                        <th>Comentario</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="notification in notifications">
                        <td><a :href="'work-order/' + notification.workorder.id +'#notas'"> {{ notification.workorder.doc_num }}</a><br>
                            {{ notification.workorder.description.substring(0,30) }}<span v-if="notification.workorder.description.length > 30">...</span>    
                        </td>
                        <td>{{ notification.author }}<br>{{ new Date(notification.created_at).toLocaleDateString('es-es', { year:"numeric", month:"numeric", day:"numeric", "hour": "2-digit", "minute":"2-digit"}) }}</td>
                        <td>{{ notification.note.substring(0,30) }}<span v-if="notification.note.length > 30">...</span></td>
                    </tr>                    
                </tbody>
            </table>
            <div v-else>No hay notificaciones pendientes</div>
        </b-card>
    </b-overlay>
    </b-container>
</template>
<script>
import { BContainer, BRow, BCard, BOverlay } from 'bootstrap-vue';
import { mapState } from 'vuex';
export default {
    components: { BContainer, BRow, BCard, BOverlay },
    data() {
        return {
            notifications: [],
            show: true
        }
    },
    created() {
        this.show = true;
        this.$http.get("/api/getPendingNotes").then((response) => {
            this.notifications = response.data;      
            this.show = false;
        })
        setInterval((scope) => {
            scope.getPendingNotes();
        }, 30000, this);     
    },
    computed: {
        ...mapState({
        user: state => state.auth.user,   
        })
    },
    methods: {
        getPendingNotes(){
            this.$http.get("/api/getPendingNotes").then((response) => {
            this.notifications = response.data;   
            // console.log(response.data);         
            // this.show = false;
        })
        }
    }
}
</script>
<style scoped lang="scss" >   
    a{
        color: #007bff;
        text-decoration: underline;
    }
</style>